import React from 'react';

import './ash-element.scss';

interface IProps {
  rarity: string;
}

export const AshRarity: React.FC<IProps> = ({ rarity }) => {
  return <div className={`ash-rarity rar-${rarity}`}>{rarity}✦</div>;
};
