import React from 'react';

import './ash-element.scss';
import { StaticImage } from 'gatsby-plugin-image';

interface IProps {
  classChar: string;
}

export const AshClass: React.FC<IProps> = ({ classChar }) => {
  return (
    <div className={`ash-class ${classChar}`}>
      {classChar === 'Bulwark' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/class_bulwark.webp"
            alt={classChar}
          />
        </>
      )}
      {classChar === 'Ranger' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/class_ranger.webp"
            alt={classChar}
          />
        </>
      )}
      {classChar === 'Skirmisher' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/class_skirmisher.webp"
            alt={classChar}
          />
        </>
      )}
      {classChar === 'Striker' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/class_striker.webp"
            alt={classChar}
          />
        </>
      )}
      {classChar === 'Support' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/class_support.webp"
            alt={classChar}
          />
        </>
      )}
      {classChar === 'Tactician' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/class_tactician.webp"
            alt={classChar}
          />
        </>
      )}
      {classChar === 'Vanguard' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/class_vanguard.webp"
            alt={classChar}
          />
        </>
      )}
      {classChar}
    </div>
  );
};
