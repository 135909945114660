import React from 'react';

import './ash-element.scss';
import { StaticImage } from 'gatsby-plugin-image';

interface IProps {
  ele: string;
}

export const AshElement: React.FC<IProps> = ({ ele }) => {
  return (
    <div className={`ash-element ${ele}`}>
      {ele === 'Corrosion' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/ele_corrosion.webp"
            alt={ele}
          />
        </>
      )}
      {ele === 'Fire' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/ele_fire.webp"
            alt={ele}
          />
        </>
      )}
      {ele === 'Ice' && (
        <>
          <StaticImage src="../../../images/ash/icons/ele_ice.webp" alt={ele} />
        </>
      )}
      {ele === 'Lightning' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/ele_lightning.webp"
            alt={ele}
          />
        </>
      )}
      {ele === 'Physical' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/ele_physical.webp"
            alt={ele}
          />
        </>
      )}
      {ele === 'Water' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/ele_water.webp"
            alt={ele}
          />
        </>
      )}
      {ele === 'Wind' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/ele_wind.webp"
            alt={ele}
          />
        </>
      )}
      {ele}
    </div>
  );
};
