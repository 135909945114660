import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IAshCharacter } from '../../common/model/graphql-types';
import { AshCharacterIcon } from './ash-character-icon';
import { AshCharacterCard } from './ash-character-card';

interface IProps {
  id?: string;
  slug: string;
  enablePopover?: boolean;
  showLabel?: boolean;
  showIcon?: boolean;
  showTags?: boolean;
  mode: string;
  unitId?: string;
}

export const AshCharacter: React.FC<IProps> = ({
  id,
  unitId,
  mode,
  showLabel,
  showIcon,
  slug,
  enablePopover
}) => {
  const allAshCharacters = useStaticQuery(graphql`
    query {
      allContentfulAshCharacter {
        nodes {
          id
          unitId
          slug
          name
          rarity
          class
          element
          isNew
          cnOnly
          isReviewPending
          smallImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          cardImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
  if ((!id && !slug && !unitId) || !mode) {
    return <div>Err</div>;
  }

  const character = allAshCharacters.allContentfulAshCharacter.nodes.find(
    (emp: IAshCharacter) =>
      id ? emp.id === id : unitId ? emp.unitId === unitId : emp.slug === slug
  );

  if (!character) {
    return <div>Err</div>;
  }

  return (
    <>
      {mode === 'icon' && (
        <AshCharacterIcon
          employee={character}
          showLabel={showLabel}
          showIcon={showIcon}
          enablePopover={enablePopover}
        />
      )}
      {mode === 'card' && (
        <AshCharacterCard
          employee={character}
          showLabel={showLabel}
          enablePopover={enablePopover}
        />
      )}
    </>
  );
};
