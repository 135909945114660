import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Alert } from 'react-bootstrap';
import { IAshCharacter } from '../../common/model/graphql-types';
import { AshRarity } from './ash-rarity';
import { AshClass } from './ash-class';
import { AshElement } from './ash-element';

interface IProps {
  character: IAshCharacter;
}

export const AshCharacterPopover: React.FC<IProps> = ({ character }) => {
  const image = character.smallImage
    ? getImage(character.smallImage.localFile.childImageSharp)
    : null;
  return (
    <div className="character-popover ash">
      <div className="top ash">
        <div className={`avatar ash rarity-${character.rarity}`}>
          {image && (
            <GatsbyImage
              className="disable-transition"
              image={image}
              alt={`${character.name}`}
            />
          )}
        </div>
        <p className="name">
          <span className="emp-name">{character.name}</span>
        </p>
        <div className="type-class">
          <AshRarity rarity={character.rarity} />
          <AshClass classChar={character.class} />
          <AshElement ele={character.element} />
        </div>
      </div>
      <Alert variant="primary">
        <p>The ratings for this character aren't available yet.</p>
      </Alert>
    </div>
  );
};
