import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import { CustomPopover } from '../../cs/common/components/custom-popover';
import { IAshCharacter } from '../../common/model/graphql-types';
import { AshCharacterPopover } from './ash-character-popover';

interface IProps {
  employee: IAshCharacter;
  enablePopover?: boolean;
  showLabel?: boolean;
}

export const AshCharacterCard: React.FC<IProps> = ({
  employee,
  showLabel,
  enablePopover
}) => {
  const image = employee.cardImage
    ? getImage(employee.cardImage.localFile.childImageSharp)
    : null;
  return (
    <CustomPopover
      enabled={enablePopover}
      customClass="character-hover-box ash"
      popover={<AshCharacterPopover character={employee} />}
    >
      <Link to={'/ash-echoes/characters/' + employee.slug}>
        <div
          className={`avatar card ash rarity-${employee.rarity} ${
            showLabel ? showLabel : ''
          }`}
        >
          {image && (
            <GatsbyImage
              className="disable-transition"
              image={image}
              alt={`${employee.name}`}
            />
          )}
          {showLabel && <span className="emp-name">{employee.name}</span>}
          {showLabel && employee.isNew && <span className="tag new">New</span>}
          {showLabel && employee.cnOnly && (
            <span className="tag future">CN</span>
          )}
        </div>
        <span className="floating-element">
          <div className="class">
            {employee.class === 'Bulwark' && (
              <>
                <StaticImage
                  src="../../../images/ash/icons/class_bulwark.webp"
                  alt={employee.class}
                />
              </>
            )}
            {employee.class === 'Ranger' && (
              <>
                <StaticImage
                  src="../../../images/ash/icons/class_ranger.webp"
                  alt={employee.class}
                />
              </>
            )}
            {employee.class === 'Skirmisher' && (
              <>
                <StaticImage
                  src="../../../images/ash/icons/class_skirmisher.webp"
                  alt={employee.class}
                />
              </>
            )}
            {employee.class === 'Striker' && (
              <>
                <StaticImage
                  src="../../../images/ash/icons/class_striker.webp"
                  alt={employee.class}
                />
              </>
            )}
            {employee.class === 'Support' && (
              <>
                <StaticImage
                  src="../../../images/ash/icons/class_support.webp"
                  alt={employee.class}
                />
              </>
            )}
            {employee.class === 'Tactician' && (
              <>
                <StaticImage
                  src="../../../images/ash/icons/class_tactician.webp"
                  alt={employee.class}
                />
              </>
            )}
            {employee.class === 'Vanguard' && (
              <>
                <StaticImage
                  src="../../../images/ash/icons/class_vanguard.webp"
                  alt={employee.class}
                />
              </>
            )}
          </div>
          <div className="element">
            {employee.element === 'Corrosion' && (
              <StaticImage
                src="../../../images/ash/icons/ele_corrosion.webp"
                alt={employee.element}
              />
            )}
            {employee.element === 'Fire' && (
              <>
                <StaticImage
                  src="../../../images/ash/icons/ele_fire.webp"
                  alt={employee.element}
                />
              </>
            )}
            {employee.element === 'Ice' && (
              <>
                <StaticImage
                  src="../../../images/ash/icons/ele_ice.webp"
                  alt={employee.element}
                />
              </>
            )}
            {employee.element === 'Lightning' && (
              <>
                <StaticImage
                  src="../../../images/ash/icons/ele_lightning.webp"
                  alt={employee.element}
                />
              </>
            )}
            {employee.element === 'Physical' && (
              <>
                <StaticImage
                  src="../../../images/ash/icons/ele_physical.webp"
                  alt={employee.element}
                />
              </>
            )}
            {employee.element === 'Water' && (
              <>
                <StaticImage
                  src="../../../images/ash/icons/ele_water.webp"
                  alt={employee.element}
                />
              </>
            )}
            {employee.element === 'Wind' && (
              <>
                <StaticImage
                  src="../../../images/ash/icons/ele_wind.webp"
                  alt={employee.element}
                />
              </>
            )}
          </div>
        </span>
      </Link>
    </CustomPopover>
  );
};
